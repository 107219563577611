import { VFC } from 'react';
import { DialogContent, Dialog, Button, Box } from '@mui/material';

const styles = {
  marginLeft: 8 ,
  marginRight: 8,
  '>p': {
    marginBottom: 2,
    textAlign: 'justify',
  },
  '>a': {
    color: 'primary.main',
    letterSpacing: '0.1em',
    '&:hover': {
      color: 'primary.dark',
    },
  },
  '>ul': {
    marginTop: 2,
    '>li': {
      'list-style-type': 'disc',
      textAlign: 'justify',
      marginBottom: 1,
    },
  },
};
const ProductDetailsModal: VFC<{ onClose: () => void; product: any }> = ({ onClose, product }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" scroll="body">
      <DialogContent className="p-0 flex justify-center items-center flex-col min-w-[400px]">
        <div className="text-2xl my-8">{product.name.en}</div>
        <Box sx={styles} dangerouslySetInnerHTML={{ __html: product.description.en }} />
        <Button onClick={onClose} className="w-full bg-black text-white mt-8 py-3 rounded-none hover:bg-slate-800">Continue</Button>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDetailsModal;
