import React, { useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';

import { showError } from 'helpers/ValidationHelpers';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'helpers/Constants';
import { useAuth } from 'context/authContext';
import Button from '../../components/Buttons/Button';
import { TextField, Link, Radio } from '@mui/material';
import { useQuery } from 'react-query';
import AxiosFactory from '../../helpers/AxiosFactory';
import { UserRegistrationInformation } from '../../types/user';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
export const VAT_REGEX: RegExp =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

type CreateAccountForm = UserRegistrationInformation & {
  country: Country | undefined;
};

export const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export type Country = { id: string; name: string; iso_3166_2: string };
const CreateAccount: VFC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateAccountForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldUnregister: true,
    defaultValues: {
      email: '',
      name: '',
      last_name: '',
      company_name: '',
      street_address: '',
      post_code: '',
      suburb: '',
      city: '',
      state: '',
      country_id: '',
      vat: '',
      type: '1',
      password: '',
      password_confirmation: '',
      country: undefined,
    },
  });

  const type = useWatch({ name: 'type', control });
  const { data: countries } = useQuery<{ data: Country[] }>(
    'countries',
    async () => await AxiosFactory.getInstance().get('guest/countries'),
  );

  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const { register: createAccount } = useAuth();
  const password = useWatch({ control, name: 'password' });

  // const country = useWatch({ name: 'country', control });
 /* const isEUCountry = useMemo(
    () => country && EU_COUNTRIES.includes(country.iso_3166_2),
    [country],
  );
*/
  const onSubmit = async (values: CreateAccountForm) => {
    try {
      setIsPending(true);
      const response = await createAccount?.(values);
      if (response) {
        navigate('/?register=true');
      }
      setIsPending(false);
    } catch (e: unknown) {
      if ((e as AxiosError).response) {
        enqueueSnackbar((e as AxiosError).response?.data.message, { variant: 'error' });
      }
      setIsPending(false);
    }
  };

  return (
    <div className="min-h-[calc(100vh-56px-30px)]">
      <div className="border-b px-2 py-8 flex w-full justify-center">
        <Container maxWidth="lg">
          <div className="self-center w-full flex justify-between">
            <h1 className="text-2xl uppercase">{t('Create Account')}</h1>
          </div>
        </Container>
      </div>
      <div className={clsx('flex items-stretch justify-center flex-col lg:px-10 px-3 bg-cover')}>
        <Container maxWidth="sm">
          <form
            className="overflow-hidden flex flex-col align-middle h-full mt-12"
            onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col sm-portrait:flex-row md:flex-col">
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{
                    required: t<string>('This field is required!'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t<string>('You must enter a valid Email address!'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label={
                        <>
                          {t('Email')}
                          <span className="text-red-500 inline-block ml-1">*</span>
                        </>
                      }
                      className="w-full"
                      error={Boolean(errors.email)}
                      helperText={showError(errors, 'email', ['required', 'pattern'])}
                    />
                  )}
                />
              </div>

              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  rules={{
                    required: t<string>('This field is required!'),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label={
                        <>
                          {t('Full Name')}
                          <span className="text-red-500 inline-block ml-1">*</span>
                        </>
                      }
                      className="w-full"
                      error={Boolean(errors.name)}
                      helperText={showError(errors, 'name', ['required'])}
                    />
                  )}
                />
              </div>
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="street_address"
                  rules={{
                    required: t<string>('This field is required!'),
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      variant="standard"
                      {...field}
                      label={t('Address (including city, state or province and post code)')}
                      className="w-full"
                      multiline
                      rows={3}
                      name="street_address"
                      error={Boolean(errors.last_name)}
                      helperText={showError(errors, 'street_address', ['required'])}
                    />
                  )}
                />
              </div>
              <div className="mb-10 w-full">
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: t<string>('This field is required!') }}
                  render={({ field }) => (
                    <Autocomplete
                      className="w-full"
                      options={countries?.data || []}
                      value={field.value}
                      onChange={(e, value) => {
                        field.onChange(value);
                        setValue('country_id', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={Boolean(errors.country)}
                          helperText={showError(errors, 'country', ['required'])}
                          label={
                            <>
                              {t('Country')}
                              <span className="text-red-500 inline-block ml-1">*</span>
                            </>
                          }
                          margin="normal"
                        />
                      )}
                      getOptionLabel={(option) => (option as Country).name}
                    />
                  )}
                />
              </div>

              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{
                    required: t<string>('This field is required!'),
                    pattern: {
                      value: PASSWORD_REGEX,
                      message: t<string>(
                        'Password must have at least 8 character and contain at least one number',
                      ),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      label={
                        <>
                          {t('Password')}
                          <span className="text-red-500 inline-block ml-1">*</span>
                        </>
                      }
                      className="w-full"
                      type="password"
                      error={Boolean(errors.password)}
                      helperText={showError(errors, 'password', ['required', 'pattern'])}
                    />
                  )}
                />
              </div>
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="password_confirmation"
                  defaultValue=""
                  rules={{
                    required: t<string>('This field is required!'),
                    validate: (value) => value === password || t<string>('Password must match!'),
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="standard"
                      {...field}
                      label={
                        <>
                          {t('Password Confirmation')}
                          <span className="text-red-500 inline-block ml-1">*</span>
                        </>
                      }
                      className="w-full"
                      type="password"
                      error={Boolean(errors.password_confirmation)}
                      helperText={showError(errors, 'password_confirmation', [
                        'required',
                        'validate',
                      ])}
                    />
                  )}
                />
              </div>
              <div className="mb-10 w-full border rounded-lg">
                <div className="bg-black text-white px-4 py-2 rounded-t-lg">
                  Billing Information
                </div>
                <div className="px-4">
                  <Controller
                    control={control}
                    name="type"
                    defaultValue="1"
                    rules={{
                      required: t<string>('This field is required!'),
                    }}
                    render={({ field }) => (
                      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" {...field}>
                        <FormControlLabel value="1" control={<Radio />} label="Personal" />
                        <FormControlLabel value="2" control={<Radio />} label="Professional" />
                      </RadioGroup>
                    )}
                  />
                </div>
              </div>

              {type === '2' && (
                <>
                  <div className="mb-10 w-full">
                    <Controller
                      control={control}
                      name="company_name"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          variant="standard"
                          {...field}
                          label={t('Club / Federation / Organism Name')}
                          className="w-full"
                          name="company_name"
                          error={Boolean(errors.last_name)}
                          helperText={showError(errors, 'company_name', ['required'])}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-10 w-full">
                    <Controller
                      control={control}
                      name="vat"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          label={t('VAT Number')}
                          className="w-full"
                          error={Boolean(errors.vat)}
                          helperText={showError(errors, 'vat', ['pattern'])}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="text-center mb-4">
              <Button type="submit" disabled={isPending}>
                {t('Create Account')}
              </Button>
            </div>

            <div className="flex items-center justify-center">
              <span className="text-center py-4 uppercase">{t('Already have an account?')}</span>
              <Link
                component={RouterLink}
                to="/login"
                className=" text-right uppercase cursor-pointer pl-4 text-slate-500 underline active:text-lightblue hover:text-lightblue-500">
                {t('Log In')}
              </Link>
            </div>
          </form>
        </Container>
      </div>
    </div>
  );
};

export default CreateAccount;
