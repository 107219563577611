import React from 'react';

import { styled } from '@mui/material/styles';
import '../footer.css';
import { useTranslation } from 'react-i18next';

const PREFIX = 'Footer';

const classes = {
  footerImg: `${PREFIX}-footerImg`
};

const Root = styled('footer')((
  {
    theme
  }
) => ({
  [`& .${classes.footerImg}`]: {
    backgroundImage: `url('/assets/images/footer_sprites.webp')`,
    display: 'inline-block',
    verticalAlign: 'middle',
  }
}));

const Footer = () => {

  const date = new Date();
  const { t } = useTranslation();

  return (
    <Root className="footer-dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-3 item">
            <div className="mg">
              <div className={`${classes.footerImg} footer-st37`}></div>
              <div className={`${classes.footerImg} footer-engarde`}></div>
              <p className="copy">© ST37 Sport et Tecnologie</p>
              <p className="copy">
                Pau - France{' '}
                <span className={`${classes.footerImg} footer-france`}></span>
              </p>
              <p className="copy">Copyright {date.getFullYear()}</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h4>{t('text.FOOTER_SOFTWARE')}</h4>
            <ul>
              <li>
                <a
                  href="https://www.st37.fr/engarde"
                  target="_blank"
                  rel="noreferrer">
                  ENGARDE
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/skouting-platform"
                  target="_blank"
                  rel="noreferrer">
                  SKOUTING
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h4>{t('text.FOOTER_HARDWARE')}</h4>
            <ul>
              <li>
                <a
                  href="https://www.st37.fr/hardware-video-kit"
                  target="_blank"
                  rel="noreferrer">
                  VIDEO KIT
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/hardware-video-kit-180"
                  target="_blank"
                  rel="noreferrer">
                  VIDEO KIT 180°
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/hardware-robot-camera"
                  target="_blank"
                  rel="noreferrer">
                  ROBOT CAMERA
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h4>{t('text.FOOTER_SERVICES')}</h4>
            <ul>
              <li>
                <a
                  href="https://www.st37.fr/services-events"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_EVENTS')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/services-development"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_DEVELOPMENT')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/services-technical-support-training"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_TECHNICAL_SUPPORT_TRAINING')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h4>{t('text.FOOTER_SOLUTIONS')}</h4>
            <ul>
              <li>
                <a
                  href="https://www.st37.fr/solutions-competition"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_COMPETITION')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/solutions-performance"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_PERFORMANCE')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/solutions-industry"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_INDUSTRY')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/solutions-soccer"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_FOOTBALL')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.st37.fr/solutions-surveillance"
                  target="_blank"
                  rel="noreferrer">
                  {t('text.FOOTER_SURVEILLANCE')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h4>{t('text.FOOTER_ABOUT_US')}</h4>
            <ul>
              <li>
                <a href={`${process.env.REACT_APP_ENGARDE_LINK}/about`}>
                  {t('text.FOOTER_WHO_WE_ARE')}
                </a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_ENGARDE_LINK}/contact`}>
                  {t('text.FOOTER_CONTACT')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h4>ENGARDE-SERVICE</h4>
            <ul>
              <li>
                <a
                  href="https://www.engarde-escrime.com/faqs"
                  target="_blank"
                  rel="noreferrer">
                  FAQ's
                </a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_ENGARDE_LINK}/legal`}>
                  {t('text.FOOTER_TERMS_AND_CONDITIONS')}
                </a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_ENGARDE_LINK}/privacy`}>
                  {t('text.FOOTER_PRIVACY_POLICY')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <br />
        <div className="col item social">
          <a
            href="https://www.instagram.com/st37.fr/"
            target="_blank"
            rel="noreferrer">
            <span className={`${classes.footerImg} footer-ig`}></span>
          </a>
          <a
            href="https://www.facebook.com/ST37-2168608986551264/"
            target="_blank"
            rel="noreferrer">
            <span className={`${classes.footerImg} footer-fb`}></span>
          </a>
          <a href="https://twitter.com/ST37fr" target="_blank" rel="noreferrer">
            <span className={`${classes.footerImg} footer-tw`}></span>
          </a>
          <a
            href="https://www.linkedin.com/company/st37-sport-et-technologie/"
            target="_blank"
            rel="noreferrer">
            <span className={`${classes.footerImg} footer-in`}></span>
          </a>
          <a
            href="https://www.youtube.com/channel/UC0FAiP4OLdJI2zg6CJvXP7w"
            target="_blank"
            rel="noreferrer">
            <span className={`${classes.footerImg} footer-yt`}></span>
          </a>
        </div>
      </div>
    </Root>
  );
};

export default Footer;
