import { VFC } from 'react';
import { DialogContent, Dialog } from '@mui/material';
import CustomerInfoForm from '../CustomerInfoForm/CustomerInfoForm';

const ContactInformationModal: VFC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" scroll="body">
      <DialogContent className="p-6 flex justify-center items-center flex-col min-w-[400px] sm:min-w-[600px]">
        <CustomerInfoForm onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default ContactInformationModal;
