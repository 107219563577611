import { loadStripe } from '@stripe/stripe-js';
import { useEffect, VFC, useState } from 'react';
import AxiosFactory from '../../helpers/AxiosFactory';
import { API_ENDPOINTS } from '../../helpers/ApiEndpoints';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { CircularProgress } from '@mui/material';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const PaymentForm: VFC<{ products: any[] }> = ({ products }) => {
  const [clientSecret, setClientSecret] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchClient = AxiosFactory.getInstance();
    const doPaymentIntent = async () => {
      const response = await fetchClient.post(API_ENDPOINTS.PRIVATE.PAYMENT_INTENT, {
        items: products,
      });
      setLoading(false);
      setClientSecret(response.data.clientSecret);
    };

    doPaymentIntent();
    //eslint-disable-next-line
  }, []);

  const appearance = {
    theme: 'stripe' as 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="flex items-center justify-center">
      {loading && <CircularProgress className="mt-6" />}
      {clientSecret && !loading && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentForm;
