
// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX: RegExp = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const PASSWORD_REGEX: RegExp = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*._]{8,100}$/;
export const CREDIT_CARD_REGEX: RegExp = /[\d| ]{16,22}/;
export const USERNAME_REGEX: RegExp = /^@[\w-_.]*$/;
export const CVC_REGEX: RegExp = /\d{3,4}/;
export const ZIP_CODE_REGEX: RegExp = /^(?!0{3})[0-9]{3,5}$/;
export const JWT_KEY: string = 'jwt';
export const LANGUAGES: { [key: string]: string } = {
  'us-EN': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
};
