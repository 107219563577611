import React, { useState } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home/Home';
import MenuAppBar from './components/MenuAppBar';
import Login from './pages/Login/Login';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import AccountConfirmation from './pages/AccountConfirmation/AccountConfirmation';
import RightDrawer from './components/RightDrawer';
import { useTranslation } from 'react-i18next';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Checkout from './pages/Checkout/Checkout';
import PaymentSuccess from './pages/PaymentSuccess/PaymentSuccess';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import useUser from './hooks/useUser';
import Footer from 'components/Footer';


const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { i18n } = useTranslation();
  const user = useUser();
  return (
    <>
      <Router>
        <MenuAppBar onToggle={() => setDrawerOpen(!drawerOpen)} />
        {!!user && (
          <RightDrawer
            language={i18n.language}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/email/verify/:id/:hash" element={<AccountConfirmation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment/status" element={<PaymentSuccess />} />
        </Routes>
        <div className="sm:hidden h-[30px] bg-[#F5F5F5] flex justify-center items-center uppercase">
          powered by
          <img
            className="cursor-pointer px-2 h-[15px]"
            src="/assets/images/logo.png"
            alt="Scouting logo"
          />
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;
