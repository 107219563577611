import useUser from '../../hooks/useUser';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { UserRegistrationInformation } from '../../types/user';
import { Country } from '../../pages/CreateAccount/CreateAccount';
import { useQuery } from 'react-query';
import AxiosFactory from '../../helpers/AxiosFactory';
import React, { useEffect, VFC } from 'react';
import { EMAIL_REGEX } from '../../helpers/Constants';
import { TextField, Radio } from '@mui/material';
import { showError } from '../../helpers/ValidationHelpers';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../helpers/ApiEndpoints';
import { useAuth } from '../../context/authContext';
import { useSnackbar } from 'notistack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type CustomerInfoFormType = Omit<
  UserRegistrationInformation,
  'password' | 'password_confirmation'
> & {
  country: Country | '' | undefined;
};

const UEcountries = {
  40:  'AT',  // Austria
  // 248: 'AX',  // Aland islands => Finland
  56:  'BE',  // Belgium
  100: 'BG',  // Bulgaria
  196: 'CY',  // Cyprus
  203: 'CZ',  // Czechia
  276: 'DE',  // Germany
  208: 'DK',  // Denmark
  233: 'EE',  // Estonia
  300: 'GR',  // Greece
  724: 'ES',  // Spain
  246: 'FI',  // Finland
  250: 'FR',  // France
  // 254: 'GF',  // French guiana => France
  // 312: 'GP',  // Guadeloupe => France
  191: 'HR',  // Croatia
  348: 'HU',  // Hungary
  372: 'IE',  // Ireland
  380: 'IT',  // Italy
  440: 'LT',  // Lithuania
  442: 'LU',  // Luxembourg
  428: 'LV',  // Latvia
  470: 'MT',  // Malta
  // 474: 'MQ',  // Martinique => France
  528: 'NL',  // Netherlands
  616: 'PL',  // Poland
  620: 'PT',  // Portugal
  // 638: 'RE',  // Reunion => France
  642: 'RO',  // Romania
  752: 'SE',  // Sweden
  705: 'SI',  // Slovenia
  703: 'SK',  // Slovakia
  // 175: 'YT',  // Mayotte => France
};

const CustomerInfoForm: VFC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const user = useUser();
  const { data: countries } = useQuery<{ data: Country[] }>(
    'countries',
    async () => await AxiosFactory.getInstance().get('guest/countries'),
  );
  const { reloadUser } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<CustomerInfoFormType>({
    defaultValues: {
      email: user?.email || '',
      name: user?.name || '',
      last_name: user?.client?.last_name || '',
      company_name: user?.client?.company_name || '',
      street_address: user?.client?.street_address || '',
      post_code: user?.client?.post_code || '',
      suburb: user?.client?.suburb || '',
      city: user?.client?.city || '',
      state: user?.client?.state || '',
      country_id: user?.client?.country_id || '',
      vat: user?.client?.vat || '',
      type: user?.client?.type.toString() || '1',
      country: '',
    },
  });
  const type = useWatch({ name: 'type', control });

  const onSubmit = async (values: CustomerInfoFormType) => {
    try {
      const fetchClient = AxiosFactory.getInstance();
      await fetchClient.post(API_ENDPOINTS.PRIVATE.UPDATE_USER, values);
      await reloadUser?.();
      enqueueSnackbar(t('User information updated successfully'), { variant: 'success' });
      onSuccess();
    } catch (e) {}
  };

  const ValidInFrance = async (vat: string) => {
    try {
      const fetchClient = AxiosFactory.getInstance()
      const response = await fetchClient.get(API_ENDPOINTS.PUBLIC.CHECK_VAT(vat),)
      return response.data.result
    } catch (e) {
      return false
    }
  };

  useEffect(() => {
    const formValues = getValues();
    if (!formValues.country && user && countries) {
      const country = countries.data.find((country) => country.id === formValues.country_id);
      setValue('country', country);
    }
  }, [countries, getValues, setValue, user]);
  return (
    <form
      className="overflow-hidden flex flex-col align-middle w-full h-full"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col sm-portrait:flex-row md:flex-col">
        <div className="mb-10 w-full">
          <Controller
            control={control}
            name="email"
            defaultValue=""
            rules={{
              required: t<string>('This field is required!'),
              pattern: {
                value: EMAIL_REGEX,
                message: t<string>('You must enter a valid Email address!'),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                disabled
                label={
                  <>
                    {t('Email')}
                    <span className="text-red-500 inline-block ml-1">*</span>
                  </>
                }
                className="w-full"
                error={Boolean(errors.email)}
                helperText={showError(errors, 'email', ['required', 'pattern'])}
              />
            )}
          />
        </div>

        <div className="mb-10 w-full">
          <Controller
            control={control}
            name="name"
            defaultValue=""
            rules={{
              required: t<string>('This field is required!'),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                label={
                  <>
                    {t('Full Name')}
                    <span className="text-red-500 inline-block ml-1">*</span>
                  </>
                }
                className="w-full"
                error={Boolean(errors.name)}
                helperText={showError(errors, 'name', ['required'])}
              />
            )}
          />
        </div>
        <div className="mb-10 w-full">
          <Controller
            control={control}
            name="street_address"
            defaultValue=""
            rules={{
              required: t<string>('This field is required!'),
            }}
            render={({ field }) => (
              <TextField
                variant="standard"
                {...field}
                label={t('Address (including city, state or province and post code)')}
                className="w-full"
                multiline
                rows={3}
                name="street_address"
                error={Boolean(errors.last_name)}
                helperText={showError(errors, 'street_address', ['required'])}
              />
            )}
          />
        </div>
        <div className="mb-10 w-full">
          <Controller
            name="country"
            control={control}
            rules={{ required: t<string>('This field is required!') }}
            render={({ field }) => (
              <Autocomplete
                className="w-full"
                options={countries?.data || []}
                value={field.value}
                onChange={(e, value) => {
                  field.onChange(value);
                  setValue('country_id', value ? value?.id : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={Boolean(errors.country)}
                    helperText={showError(errors, 'country', ['required'])}
                    label={
                      <>
                        {t('Country')}
                        <span className="text-red-500 inline-block ml-1">*</span>
                      </>
                    }
                    margin="normal"
                  />
                )}
                getOptionLabel={(option) => (option as Country).name ?? ''}
                isOptionEqualToValue={(option, value) => (option === "" && value === "") || ((option as Country).id === (value as Country).id)}
              />
            )}
          />
        </div>
        <div className="mb-10 w-full border rounded-lg">
          <div className="bg-black text-white px-4 py-2 rounded-t-lg">Billing Information</div>
          <div className="px-4">
            <Controller
              control={control}
              name="type"
              defaultValue="1"
              rules={{
                required: t<string>('This field is required!'),
              }}
              render={({ field }) => (
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" {...field}>
                  <FormControlLabel value="1" control={<Radio />} label="Personal" />
                  <FormControlLabel value="2" control={<Radio />} label="Professional" />
                </RadioGroup>
              )}
            />
          </div>
        </div>

          {type === '2' && (
              <>
                  <div className="mb-10 w-full">
                      <Controller
                          control={control}
                          name="company_name"
                          defaultValue=""
                          render={({ field }) => (
                              <TextField
                                  variant="standard"
                                  {...field}
                                  label={t('Club / Federation / Organism Name')}
                                  className="w-full"
                                  name="company_name"
                                  error={Boolean(errors.last_name)}
                                  helperText={showError(errors, 'company_name', ['required'])}
                              />
                          )}
                      />
                  </div>
                  <div className="mb-10 w-full">
                      <Controller
                          control={control}
                          name="vat"
                          defaultValue=""
                          rules={{
                            validate: {
                              required: async value => {
                                if (!value && getValues('type') === '2' && Number(getValues('country_id')) === 250) {
                                  return 'Required when Billing Professional in France'
                                }
                                else if (value && getValues('type') === '2' && Number(getValues('country_id')) === 250 ) {
                                  const vif = await ValidInFrance(value)
                                  if (!vif) return 'valid VAT is required'
                                }
                                else if(value && getValues('type') === '2' && getValues('country_id') in UEcountries && !value.match("^[A-Za-z0-9]+$")) {
                                  return `VAT for an UE country should only contains letters and numbers`
                                }
                                else if (value && getValues('type') === '2' && getValues('country_id') in UEcountries) {
                                  const countryCode = UEcountries[Number(getValues('country_id')) as keyof typeof UEcountries];
                                  if (value.slice(0, 2) !== countryCode) {
                                    return `Missing country code (${countryCode}) at the begining of the VAT for an UE country`
                                  }
                                }
                                return true
                              },
                            },
                          }}
                          render={({ field }) => (
                              <TextField
                                  {...field}
                                  variant="standard"
                                  label={t('VAT Number')}
                                  className="w-full"
                                  error={Boolean(errors.vat)}
                                  helperText={showError(errors, 'vat', ['required','pattern'])}
                              />
                          )}
                      />
                  </div>
              </>
          )}
      </div>
      <div className="text-center mb-4">
        <Button type="submit">{t('Update Account')}</Button>
      </div>
    </form>
  );
};

export default CustomerInfoForm;
