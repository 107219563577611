import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const IcCreate: React.FC<SvgIconProps> = ({ style, ...props }) => {
  return (
    <SvgIcon style={style} viewBox="0 0 31 24" {...props}>
      <path
        d="M27,11.6V3.7c0-1.9-1.6-3.5-3.5-3.5H3.5C1.6,0.2,0,1.8,0,3.7v13.9c0,1.9,1.6,3.5,3.5,3.5h16
        c1.1,1.6,3,2.6,5.1,2.6c3.5,0,6.3-2.8,6.3-6.3C31,14.8,29.3,12.5,27,11.6z M17.3,11.3l-5.9,3.5c-0.6,0.4-1.2,0.1-1.2-0.7V7.2
        c0-0.7,0.5-1,1.2-0.7l5.9,3.5C18,10.4,18,11,17.3,11.3z M29.2,17.5c0,2.5-2,4.5-4.5,4.5c0,0,0,0,0,0c0,0,0,0,0,0
        C22.1,22,20,20,20,17.4c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.5,1.9,4.6,4.3c0,0,0,0.1,0,0.1C29.2,17.3,29.2,17.4,29.2,17.5
        C29.2,17.4,29.2,17.5,29.2,17.5C29.2,17.5,29.2,17.5,29.2,17.5z"
      />
      <path
        d="M26.9,16.9h-1.6v-1.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v1.6h-1.6c-0.3,0-0.6,0.3-0.6,0.6
        c0,0.3,0.3,0.6,0.6,0.6h1.6v1.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-1.6h1.6c0.3,0,0.6-0.3,0.6-0.6
        C27.5,17.2,27.2,16.9,26.9,16.9z"
      />
    </SvgIcon>
  );
};

export default IcCreate;
