export const API_ENDPOINTS = {
  PRIVATE: {
    GET_USER: 'auth/user',
    UPDATE_USER: 'auth/user',
    PAYMENT_INTENT: 'auth/payment/intent',
  },
  PUBLIC: {
    LOGIN: 'auth/login',
    EMAIL_CONFIRMATION: (userId: string, hash: string, expires: string, signature: string) =>
      `email/verify/${userId}/${hash}?expires=${expires}&signature=${signature}`,
    LOGOUT: 'auth/logout',
    REGISTER: 'auth/register',
    FORGOT_PASSWORD: 'auth/recovery',
    RESET_PASSWORD: 'auth/recovery-confirmation',
    CHANGE_PASSWORD: 'auth/change-password',
    COUNTRIES: 'countries',
    products: 'guest/products',
    CHECK_VAT: (vat: string) =>`guest/check-vat/${vat}`,
  },
};
