// @ts-ignore
import { fadeIn } from 'react-animations';
import Radium from 'radium';
import { CSSProperties } from 'react';

const stylesAnimations = (time = 1): Partial<CSSProperties> => {
  return {
    animation: `x ${time}s`,
    animationName: Radium.keyframes(fadeIn, 'fadeIn') as string,
  };
};

export default stylesAnimations;
