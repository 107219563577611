import useUser from '../../hooks/useUser';
import { useLocation, Navigate } from 'react-router-dom';
import { Container, Button } from '@mui/material';
import IcEdit from '../../components/Icons/IcEdit';
import { useTranslation } from 'react-i18next';
import { useCart } from 'react-use-cart';
import { useQuery } from 'react-query';
import AxiosFactory from '../../helpers/AxiosFactory';
import React, { useCallback, useMemo } from 'react';
import useModal from '../../hooks/useModal';
import ContactInformationModal from '../../components/modals/ContactInformationModal';
import PaymentModal from '../../components/modals/PaymentModal';
import { getProductName } from '../Home/Home';
import { EU_COUNTRIES } from '../CreateAccount/CreateAccount';

export const currencyFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const Checkout = () => {
  const user = useUser();
  let location = useLocation();
  const { t } = useTranslation();
  const { setModal, unSetModal } = useModal();
  const { data } = useQuery<{ data: any[] }>(
    'guest/products',
    async () => await AxiosFactory.getInstance().get('guest/products'),
  );

  const { items, addItem, getItem, updateItemQuantity, removeItem } = useCart();
  const handleDecreaseQuantity = (product: any) => {
    const item = getItem(product.id);
    if (!item) {
      return;
    }

    if (item.quantity - 1 === 0) {
      removeItem(product.id);
      return;
    }

    updateItemQuantity(product.id, item.quantity - 1);
  };
  const handleQuantityChange = (product: any, value: any) => {
    let quantity = value ? parseInt(value) : 0;
    const item = getItem(product.id);
    quantity = quantity < 0 ? 0 : quantity;
    if (item && quantity === 0) {
      removeItem(product.id);
      return;
    }

    if (item && quantity) {
      updateItemQuantity(product.id, quantity);
      return;
    }
    addItem(product, quantity);
  };
  const handleIncreaseQuantity = (product: any) => {
    const item = getItem(product.id);
    if (!item) {
      addItem(product, 1);
      return;
    }

    updateItemQuantity(product.id, item.quantity + 1);
  };

  const getItemQuantity = useCallback(
    (product) => {
      return getItem(product.id)?.quantity || 0;
    },
    [getItem],
  );
  const getPrice = (product: any) => {
    return product.price;
  };

  const subtotal = useMemo(() => {
    return items.reduce((prev, current) => {
      return prev + getPrice(current) * (current.quantity || 0);
    }, 0);
    //eslint-disable-next-line
  }, [getPrice, items, user]);
  const isEUCountry = useMemo(
    () => user?.client.country && EU_COUNTRIES.includes(user?.client.country.iso_3166_2),
    [user?.client.country],
  );

  const taxPercentage = useMemo(() => {
    if (user?.client.country.iso_3166_2.toLowerCase() === 'fr') {
      return 20;
    }

    if (user?.client.type === 1 || user?.client.type === '1') {
      return 20;
    }

    if (user?.client.valid_vat) {
      return 0;
    }

    if (!isEUCountry) {
      return 0;
    }

    return 20;
  }, [isEUCountry, user?.client.country.iso_3166_2, user?.client.type, user?.client.valid_vat]);

  const tax = useMemo(() => (subtotal * taxPercentage) / 100, [subtotal, taxPercentage]);

  const total = useMemo(() => {
    return subtotal + tax;
  }, [subtotal, tax]);

  const handleEditCustomer = () => {
    setModal(<ContactInformationModal onClose={unSetModal} />);
  };

  const handleCheckout = () => {
    setModal(<PaymentModal onClose={unSetModal} products={items} />);
  };
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (items.length === 0) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <div className="border-b px-2 py-8 flex w-full justify-center">
        <Container maxWidth="lg">
          <div className="self-center w-full flex justify-between">
            <h1 className="text-2xl ">CHECKOUT</h1>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className="mb-12">
        <div className="mt-12 text-sm text-[17px] h-[65px] bg-gray-100 uppercase py-2 px-4 tracking-widest border rounded-t-lg flex items-center">
          <span className="flex-1">{t('customer information')}</span>
          <Button
            className="min-w-[110px] bg-lightblue text-white rounded-full hover:bg-lightblue-700 flex items-center"
            size="small"
            onClick={handleEditCustomer}
            sx={{
              minWidth: 0,
              height: 45,
              fontSize: 14,
            }}>
            <IcEdit className="mr-2 !text-[15px]" />
            Edit
          </Button>
        </div>
        <div className="border-l border-r border-b rounded-b-lg p-4">
          <div className="flex flex-col md:flex-row">
            <div className="grid grid-rows-4 grid-flow-col gap-4 md:grid-rows-2 flex-1">
              <div>
                <div className=" uppercase text-slate-400 text-xs mb-1">{t('contact')}:</div>
                <div className="font-bold">{user.name}</div>
              </div>
              <div>
                <div className=" uppercase text-slate-400 text-xs">{t('email')}:</div>
                <div className="font-bold">{user.email}</div>
              </div>
              <div>
                <div className=" uppercase text-slate-400 text-xs">{t('address')}:</div>
                <div className="font-bold">{user.client.street_address || 'n/a'}</div>
              </div>
              <div>
                <div className=" uppercase text-slate-400 text-xs">{t('country')}:</div>
                <div className="font-bold">{user.client.country.name}</div>
              </div>
              {(user?.client.type !== '1' && user?.client.type !== 1 ) && (
                <>
                  <div>
                    <div className=" uppercase text-slate-400 text-xs">
                      {t('Club / Federation / Organism Name')}:
                    </div>
                    <div className="font-bold">{user.client.company_name || 'n/a'}</div>
                  </div>
                  <div>
                    <div className=" uppercase text-slate-400 text-xs">{t('tax ID')}:</div>
                    <div className="font-bold">
                      {user.client.vat || 'n/a'}{' '}
                      {user.client.valid_vat ? (
                        <span className="text-sm text-slate-100">(valid vat, tax deducted)</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              )}
              <div>
                <div className=" uppercase text-slate-400 text-xs">{t('Billing Type')}:</div>
                <div className="font-bold">
                  {user?.client.type === '1' || user?.client.type === 1 ? 'Personal' : 'Professional'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 text-sm text-[17px] h-[65px] bg-gray-100 uppercase py-2 px-4 tracking-widest border rounded-t-lg flex items-center">
          {t('order summary')}
        </div>

        {data?.data && (
          <div className="border-l border-r border-b rounded-b-lg">
            {items.map((item) => {
              const product = data?.data.find((prod) => prod.id === item.id);
              const productName = getProductName(product);
              return (
                <div
                  key={item.id}
                  className="flex flex-col sm:flex-row sm:items-center p-4 border-b ">
                  <div className="flex flex-1 flex-col justify-center mb-4 sm:mb-0">
                    <h4 className="flex-1 text-xl">
                      {productName.name}{' '}
                      <span className="uppercase font-extrabold">
                        {productName.planType} {productName.period}
                      </span>
                    </h4>
                  </div>
                  <div className="font-poppins sm:w-48 sm:text-right text-[#414141] mr-6 mb-2 sm:mb-0">
                    {currencyFormatter.format(getPrice(product))}
                  </div>
                  <div
                    className={`rounded-full p-2 border flex border-slate-300 group hover:border-lightblue-500 ${
                      getItemQuantity(product) > 0 && 'bg-lightblue border-lightblue-500'
                    }`}>
                    <div
                      className={`px-2 hover:text-lightblue-500 cursor-pointer ${
                        getItemQuantity(product) > 0 && 'text-white hover:text-gray-200'
                      }`}
                      onClick={() => handleDecreaseQuantity(product)}>
                      -
                    </div>
                    <input
                      type="number"
                      min={0}
                      max={1000}
                      className={`w-full sm:w-8 text-center ${
                        getItemQuantity(product) > 0 && 'text-white bg-lightblue'
                      }`}
                      value={getItemQuantity(product).toString()}
                      onChange={(event) => handleQuantityChange(product, event.target.value)}
                    />
                    <div
                      className={`px-2 hover:text-lightblue-500 cursor-pointer ${
                        getItemQuantity(product) > 0 && 'text-white hover:text-gray-200'
                      }`}
                      onClick={() => handleIncreaseQuantity(product)}>
                      +
                    </div>
                  </div>
                  <div className="font-poppins font-extrabold sm:w-48 sm:text-right text-[#414141] mt-2 sm:mt-0">
                    {currencyFormatter.format(getPrice(product) * (item.quantity || 0))}
                  </div>
                </div>
              );
            })}
            <div className="p-4">
              <div className="flex flex-col sm:flex-row">
                <div className="flex flex-col mb-8 w-1/2">
                  <div className="uppercase text-xs mb-4">Pay With:</div>
                  <div className="flex gap-4 flex-wrap">
                    <div className="border-2 p-3 rounded h-[45px] w-[64px]  justify-center flex items-center">
                      <img src="/assets/images/visa.png " alt="" />
                    </div>
                    <div className="border-2 p-3 rounded h-[45px]  w-[64px] justify-center flex items-center">
                      <img src="/assets/images/master.png" alt="" />
                    </div>
                    <div className="border-2 p-3 rounded h-[45px]  w-[64px]  justify-center flex items-center">
                      <img src="/assets/images/amex.png" alt="" />
                    </div>
                    <div className="border-2 p-3 rounded h-[45px]  w-[64px]  justify-center flex items-center">
                      <img src="/assets/images/discovery.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-3 flex-1">
                  <div className="flex flex-col sm:items-end col-span-2 text-xs gap-2 uppercase justify-center">
                    <div className="">sub total:</div>
                  </div>
                  <div className="flex flex-col sm:items-end col-span-2 text-xs gap-2 uppercase justify-center">
                    <div className="">tva/tax ({taxPercentage}%):</div>
                  </div>
                  <div className="flex flex-col sm:items-end col-span-2 text-xs gap-2 uppercase">
                    <div className="pt-2">grand total:</div>
                  </div>
                  <div className="flex flex-col items-end col-span-2 gap-2 text-sm justify-center uppercase">
                    <span className="text-md">{currencyFormatter.format(subtotal)}</span>
                  </div>
                  <div className="flex flex-col items-end col-span-2 justify-center text-sm gap-2 uppercase">
                    <span className="text-md">{currencyFormatter.format(tax)}</span>
                  </div>
                  <div className="min-w-[150px] pt-0.5 flex flex-col items-end col-span-2 gap-2 uppercase">
                    <span className="font-extrabold text-xl">
                      {currencyFormatter.format(total)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                onClick={handleCheckout}
                className="w-full bg-lightblue text-white rounded-none rounded-b-lg hover:bg-lightblue-700">
                Place Order
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Checkout;
