import { ButtonUnstyled } from '@mui/base';
import { FC } from 'react';
import clsx from 'clsx';
const ToggleButton: FC<{
  value: string;
  selected: boolean;
  onChange: (value: string, isSelected: boolean) => void;
}> = ({ children, value, selected, onChange }) => {
  if (selected) {
    return (
      <ButtonUnstyled
        onClick={() => onChange(value, !selected)}
        className={clsx(
          `uppercase text-sm px-[30px] tracking-[0.11em] text-[14px] inline-flex font-bold h-[40px] text-white items-center justify-center cursor-pointer rounded-full py-2 px-4 border bg-lightblue-500 text‑lightblue-500 hover:bg-lightblue-700 hover:border-transparent`,
          {},
        )}>
        {children}
      </ButtonUnstyled>
    );
  }
  return (
    <ButtonUnstyled
      onClick={() => onChange(value, !selected)}
      className={clsx(
        `uppercase px-[30px] tracking-[0.11em] text-[14px] inline-flex font-bold h-[40px] items-center justify-center cursor-pointer rounded-full py-2 px-4 hover:border-lightblue-500 border border-transparent `,
      )}>
      {children}
    </ButtonUnstyled>
  );
};

export default ToggleButton;
