import { useQuery } from 'react-query';
import AxiosFactory from '../../helpers/AxiosFactory';
import React, { useState, useCallback, useEffect } from 'react';
import IcCart from '../../components/Icons/IcCart';
import IcDownolad from '../../components/Icons/IcDownolad';
import ToggleButton from '../../components/Buttons/ToggleButton';
import { useCart } from 'react-use-cart';
import useModal from '../../hooks/useModal';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AccountCreationSuccessModal from '../../components/modals/AccountCreationSuccessModal';
import { Button, Container, CircularProgress, Link } from '@mui/material';
import useUser from '../../hooks/useUser';
import ProductDetailsModal from '../../components/modals/ProductDetailsModal';
import { currencyFormatter } from '../Checkout/Checkout';

export const getProductName = (product: any) => {
  const fullName = product.name.en;
  const [plan] = fullName.split(' - ');
  const displayCodes = ['E', 'F', 'G'];
  const basicCodes = ['A', 'B'];
  const proCodes = ['C', 'D'];
  let prodName = displayCodes.includes(plan) ? 'Display License' : 'Engarde Licence';
  let period = '';
  let planType = basicCodes.includes(plan) ? 'BASIC' : '';
  planType = proCodes.includes(plan) ? 'PRO' : planType;

  switch (plan) {
    case 'C':
    case 'F':
    case 'A':
      period = '3 Months';
      break;
    case 'B':
    case 'D':
    case 'G':
      period = '1 year';
      break;
    default:
      period = '5 Days';
  }
  return {
    period,
    planType,
    name: prodName,
  };
};

const Home = () => {
  const { data, isLoading } = useQuery<{ data: any[] }>(
    'guest/products',
    async () => await AxiosFactory.getInstance().get('guest/products'),
  );
  const { setModal, unSetModal } = useModal();
  const [searchParams] = useSearchParams();
  const successfulRegistration = searchParams.get('register');
  const navigate = useNavigate();
  const user = useUser();
  useEffect(() => {
    if (successfulRegistration === 'true') {
      setModal(<AccountCreationSuccessModal onClose={unSetModal} />);
    }
  }, [setModal, successfulRegistration, unSetModal]);
  const [selectedTab, setSelectedTab] = useState<string>('store');

  const { items, addItem, getItem, updateItemQuantity, removeItem } = useCart();
  const handleDecreaseQuantity = (product: any) => {
    const item = getItem(product.id);
    if (!item) {
      return;
    }

    if (item.quantity - 1 === 0) {
      removeItem(product.id);
      return;
    }

    updateItemQuantity(product.id, item.quantity - 1);
  };
  const handleQuantityChange = (product: any, value: any) => {
    let quantity = value ? parseInt(value) : 0;
    const item = getItem(product.id);
    quantity = quantity < 0 ? 0 : quantity;
    if (item && quantity === 0) {
      removeItem(product.id);
      return;
    }

    if (item && quantity) {
      updateItemQuantity(product.id, quantity);
      return;
    }
    addItem(product, quantity);
  };
  const handleIncreaseQuantity = (product: any) => {
    const item = getItem(product.id);
    if (!item) {
      addItem(product, 1);
      return;
    }

    updateItemQuantity(product.id, item.quantity + 1);
  };

  const getItemQuantity = useCallback(
    (product) => {
      return getItem(product.id)?.quantity || 0;
    },
    //eslint-disable-next-line
    [getItem, items],
  );

  const checkout = () => {
    if (user) {
      navigate('/checkout');
      return;
    }

    navigate('/login');
  };

  const getPrice = (product: any) => {
    return product.price;
  };

  const getPriceWithTaxes = (product: any) => {
    return product.price * 1.2;
  };

  const learMoreClick = (product: any) => {
    setModal(<ProductDetailsModal onClose={unSetModal} product={product} />);
  };
  return (
    <div className="text-slate-500 flex flex-col">
      <div className="border-b sm:px-2 sm:py-8 pt-4 flex w-full justify-center">
        <Container maxWidth="lg" className="px-0">
          <div className="self-center w-full flex flex-col sm:flex-row justify-between items-center">
            <h1 className="text-2xl py-2 font-bold">BOUTIQUE ENGARDE</h1>
            <div className="flex gap-2 mt-3 py-2 sm:py-0 sm:mt-0 bg-gray-100 w-full sm:w-auto justify-center sm:bg-transparent">
              <ToggleButton
                value="store"
                selected={selectedTab === 'store'}
                onChange={() => setSelectedTab('store')}>
                <IcCart className="text-[18px] mr-2" /> Store
              </ToggleButton>
              <ToggleButton
                value="download"
                selected={selectedTab === 'download'}
                onChange={() => setSelectedTab('download')}>
                <IcDownolad className="text-[18px] mr-2" />
                Software Download
              </ToggleButton>
            </div>
          </div>
        </Container>
      </div>
      {selectedTab === 'store' && (
        <Container maxWidth="lg" className="px-0 sm:px-6">
          {isLoading && (
            <div className="mt-12 flex items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {!isLoading && (
            <div className="mx-2  max-w-7xl self-center w-full">
              {data?.data.map((product) => {
                const productName = getProductName(product);
                return (
                  <div
                    key={product.id}
                    className="flex flex-col sm:flex-row sm:items-center px-2 sm:px-0 my-6 sm:my-12 border-b pb-8">
                    <div className="flex flex-1 flex-col justify-center mb-4 sm:mb-0">
                      <h4 className="flex-1 pb-4 text-xl">
                        {productName.name}{' '}
                        <span className="uppercase font-extrabold">
                          {productName.planType} {productName.period}
                        </span>
                      </h4>
                      <span
                        onClick={() => learMoreClick(product)}
                        className="text-lightblue-500 cursor-pointer text-sm underline font-bold hover:text-lightblue-700 tracking-widest">
                        LEARN MORE
                      </span>
                    </div>
                    <div className=" text-xl w-48 mb-2 sm:mb-0 sm:text-center flex flex-col">
                      {currencyFormatter.format(getPrice(product))}
                      <div className="text-sm text-disabled">{currencyFormatter.format(getPriceWithTaxes(product))} all taxes included / toutes taxes comprises (TTC)</div>
                    </div>

                    <div
                      className={`rounded-full p-2 mr-4 sm:mr-0 border flex border-slate-300 group hover:border-lightblue-500 ${
                        getItemQuantity(product) > 0 && 'bg-lightblue border-lightblue-500'
                      }`}>
                      <div
                        className={`px-2 hover:text-lightblue-500 cursor-pointer ${
                          getItemQuantity(product) > 0 && 'text-white hover:text-gray-200'
                        }`}
                        onClick={() => handleDecreaseQuantity(product)}>
                        -
                      </div>
                      <input
                        type="number"
                        min={0}
                        max={1000}
                        className={`w-full text-center ${
                          getItemQuantity(product) > 0 && 'text-white bg-lightblue'
                        }`}
                        value={getItemQuantity(product).toString()}
                        onChange={(event) => handleQuantityChange(product, event.target.value)}
                      />
                      <div
                        className={`px-2 hover:text-lightblue-500 cursor-pointer ${
                          getItemQuantity(product) > 0 && 'text-white hover:text-gray-200'
                        }`}
                        onClick={() => handleIncreaseQuantity(product)}>
                        +
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                <Button
                  onClick={checkout}
                  className="w-full bg-lightblue text-white rounded-t-none py-3 mb-12 hover:bg-lightblue-700">
                  Place Order
                </Button>
              </div>
            </div>
          )}
        </Container>
      )}
      {selectedTab === 'download' && (
        <div className="flex items-center justify-center mt-12">
          <div className="border flex max-w-[350px] w-full flex-col items-center justify-center rounded mb-28">
            <div className="mt-6 mb-3">
              <svg
                width="61"
                height="60"
                viewBox="0 0 61 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="60" height="60" rx="30" fill="#67C3D8" />
                <path
                  d="M30.474 39.7431C28.9626 39.797 27.4513 39.6351 26.0749 39.0144C22.7284 37.557 20.7582 35.0471 20.1105 31.4577C20.0295 31.0259 20.0295 30.5671 20.0025 30.1352C19.9756 29.6225 20.2994 29.2716 20.7312 29.2716C21.163 29.2716 21.4869 29.6225 21.4869 30.1352C21.5409 33.5088 23.6999 36.5854 26.8576 37.7189C27.8022 38.0698 28.7737 38.2317 29.7723 38.2317C30.7978 38.2317 31.8504 38.2857 32.8759 38.0698C34.954 37.638 36.6543 36.5584 37.9227 34.8582C38.9753 33.4278 39.5151 31.8085 39.5151 30.0543C39.5151 29.5955 39.8389 29.2446 40.2707 29.2446C40.7025 29.2446 41.0264 29.5955 40.9994 30.0543C40.8375 33.6977 39.2452 36.5045 36.1415 38.3936C34.4143 39.4462 32.4981 39.824 30.474 39.7431Z"
                  fill="white"
                />
                <path
                  d="M31.2294 30.405C32.066 29.5414 32.8757 28.7048 33.6584 27.9221C34.1172 27.4633 34.7649 27.6252 34.9538 28.219C35.0348 28.5428 34.8998 28.7857 34.6839 29.0016C33.7393 29.9732 32.7677 30.9448 31.8232 31.9433C31.5803 32.2132 31.3104 32.4561 31.0405 32.726C30.6896 33.0769 30.2578 33.0769 29.907 32.726C28.6925 31.4845 27.4511 30.2161 26.2366 28.9746C25.8857 28.6238 25.8857 28.165 26.2096 27.8681C26.5335 27.5713 26.9653 27.5982 27.2891 27.9491C27.9908 28.6778 28.7195 29.4065 29.4212 30.1351C29.5022 30.2161 29.5561 30.2971 29.6371 30.378C29.6641 30.378 29.6911 30.351 29.7181 30.351C29.7181 30.2701 29.7181 30.1621 29.7181 30.0812C29.7181 27.8681 29.7181 25.6551 29.7181 23.4421C29.7181 23.3341 29.7181 23.2261 29.7451 23.1182C29.826 22.7404 30.1769 22.4705 30.5277 22.4975C30.9056 22.5245 31.2024 22.8483 31.2294 23.2531C31.2294 23.3341 31.2294 23.4151 31.2294 23.496C31.2294 25.6821 31.2294 27.8681 31.2294 30.0542C31.2294 30.1351 31.2294 30.2431 31.2294 30.405Z"
                  fill="white"
                />
                <path
                  d="M29.7451 20.6352C29.7451 20.4733 29.7182 20.3114 29.7451 20.1494C29.7991 19.7716 30.177 19.4747 30.5278 19.5017C30.9056 19.5287 31.2295 19.8256 31.2295 20.2304C31.2295 20.5003 31.2295 20.7432 31.2295 21.013C31.2025 21.4179 30.8786 21.7417 30.5008 21.7417C30.123 21.7417 29.7721 21.4179 29.7451 21.013C29.7182 20.8781 29.7451 20.7432 29.7451 20.6352Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="">Engarde latest version</div>
            {/* <div className="mb-8">V10.26.0</div> */}
            <Link
              href="https://www.engarde-escrime.com/download-and-setup"
              target="_blank"
              className="text-sm no-underline w-full bg-black uppercase text-center p-3 text-white hover:text-white hover:bg-gray-600 rounded-t-none">
              Go to Download
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
